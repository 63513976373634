import "./About.scss"

export default function About() {
  return (
    <main>
      <div class="content about">
        <div class="about-text">
          <h2>About Alice</h2>
          <p>Alice is a product designer on the design system team at 1Password.
          </p>
          <p>Prior to entering the working world, Alice studied English at Vanderbilt University. She owes her first foray into design to Tumblr and her cracked copy of Photoshop. Her time spent as a collegiate hackathon organizer (thanks, VandyHacks) and Computer Science minor (thanks, Professor Anders) led her to the field of product design.</p>
          <p>When she's not designing, Alice enjoys drawing, bouldering, playing guitar, attending live theater, exploring New York City, and writing speculative fiction.</p>
          {/* <br />
          <hr />
          <br /> */}
          {/* 
          <p>I love:</p>
          <ul>
            <li></li>
            <li>17th century Renaissance poet John Donne</li>
            <li>Carly Rae Jepsen's 2015 album E•MO•TION</li>
            <li>Botanical gardens</li>
          </ul> */}
          {/* <p className="currently">Favorite literature: <i>The Traitor Baru Cormorant</i> by Seth Dickinson, <i>Persuasion</i> by Jane Austen, <i>Tehanu</i> by Ursula K. Le Guin, <i>The Bluest Eye</i> by Toni Morrison, <i>Anna Karenina</i> by Leo Tolstoy, <i>The Fifth Head of Cerberus</i> by Gene Wolfe,  <i>Wuthering Heights</i> by Emily Brontë, <i>Braiding Sweetgrass</i> by Robin Wall Kimmerer, <i>Earthlings</i> by Sayaka Murata, <i>Pale Fire</i> by Vladimir Nabokov, <i>The Left Hand of Darkness</i> by Ursula K. Le Guin, "The Overcoat" by Nikolai Gogol,  "Homecoming Is Just Another Word for the Sublimation of the Self" by Isabel J. Kim, "Sea Oak" by George Saunders, "Looking Back" by Ursula K. Le Guin, "The Canonization" by John Donne, "Think of Others," by Mahmoud Darwish, "A Valediction: Forbidding Mourning" by John Donne, and "Friendship's Mystery, to My Dearest Lucasia" by Katherine Philips.</p> */}
        </div>
      </div>
    </main>
  )
}
